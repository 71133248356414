@font-face {
  font-family: 'Fredoka One';
  src: url(./font/FredokaOne-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url(./font/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url(./font/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url(./font/SourceSansPro-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Source Serif Pro';
  src: url(./font/SourceSerifPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  src: url(./font/SourceSerifPro-Bold.ttf) format('truetype');
  font-weight: bold;
}


body {
  background-color: white;
  margin: 0;
  /* font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-card-extra {
  padding: 0 !important;
}

.ant-layout {
  background-color: white !important;
}