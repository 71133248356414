@media only screen and (max-width: 768px) {
    .nav {
        display: none;
    }
    .header-bar {
        padding: 10px 15px !important;
    }
}

::-webkit-scrollbar {
    background-color: #e8e8e8;
    width: 12px
}

::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 20px;
    width: 8px;
}

@media only screen and (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 6px
    }
    ::-webkit-scrollbar-thumb {
        width: 4px;
    }
}

::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
    border-radius: 20px;
}

.ant-carousel .slick-dots li button {
    background-color: #e8e8e8;
    opacity: 1;
}

.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #0672bf;
}

.ant-select-selector {
    border-radius: 25px !important;
}

.ant-table-title {
    padding: 0 !important;
    margin-bottom: 14px !important
}

.ant-table-wrapper {
    margin: 0 !important;
}

.firebaseui-idp-list {
    margin: 0 !important;
}

.firebaseui-idp-list>.firebaseui-list-item {
    margin: 0 !important;
}

.firebaseui-idp-button {
    box-shadow: none !important;
}

.firebaseui-idp-button:hover{
    box-shadow: none !important;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.page-link {
    color: white;
    font-family: Source Sans Pro;
    font-size: 1.2em;
}

.page-link:hover {
    color: white;
    opacity: 0.8;
    transition: opacity 0.2s;
}

.page-link:active {
    opacity: 0.7;
}

.profile-button:hover {
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;
}

.topic-icon:hover {
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;
}

.katex-display {
    margin: 0px;
}

@media(hover: hover) and (pointer: fine) {
    .iconButton:hover {
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.2s;
    }
}